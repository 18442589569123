.skeleton {
    position: relative;
}

span.skeleton {
    display: inline-block;
}

.skeleton::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 105%;
    min-height: 1.125em;
    left: 0;
    top: 0;
    border-radius: 0.25rem;
    opacity: 1;
    transition: opacity 0.3s;
    background: linear-gradient(110deg, #ddd 8%, #eee 18%, #ddd 33%);
    background-size: 200% 100%;
    animation: 2.5s skeletonLoad linear infinite;
}

.skeleton-loaded::after {
    opacity: 0;
    pointer-events: none;
}

@keyframes skeletonLoad {
    to {
        background-position-x: -200%;
    }
}

.video-player-placeholder {
    position: relative;
    aspect-ratio: 16/9;
    width: 100%;
    max-height: 350px;
}

.video-player-placeholder .skeleton {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
}