$white: #fff;
$gray-100: #f8f9fd;
$gray-200: #f0f2fa;
$gray-300: #e6e6e6;
$gray-400: #d3d3d3;
$gray-500: #949495;
$gray-600: #737373;
$gray-700: #4f575e;
$gray-800: #202022;
$gray-900: #212529;
$gray-950: #030335;
$black: #000;

$blue: #2155ff;
$red: #ff4242;
$orange: #ffc75a;
$yellow: #ffc121;
$green: #00bb8c;
$cyan: #0dcaf0;

$primary: #F47621;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $orange;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;
$darker: $gray-950;

$font-tiny: .75rem;
$font-smaller: .85rem;
$font-small: .9rem;
$font-regular: 1rem;
$font-medium: 1.125rem; // Usually 18px

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-dark: 800;
$font-weight-black: 900;



$box-shadow-sm: 0 2px 4px rgba($black, .15);
$box-shadow: 0 2px 8px rgba($black, .15);

$border-radius: .5rem;

$screen-sm-min: 600px;
$screen-md-min: 900px;
$screen-lg-min: 1200px;
$screen-xl-min: 1536px;